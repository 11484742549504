<template>
	<div class="pb-4"
		:class="{
			'-mt-4': anEvent.status === 'accepted'
		}">
		<!-- <div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div> -->
		<section v-if="anEvent.status === 'accepted'">
			<div>
				<b-breadcrumb>
					<b-breadcrumb-item href="#/events/list">Events</b-breadcrumb-item>
					<i class="px-2 inactive fe fe-chevron-right"></i>
					<b-breadcrumb-item active-class="active" active
						>Attendee information</b-breadcrumb-item
						>
				</b-breadcrumb>
			</div>
		</section>
		<div class="d-xl-flex">
			<div class="col-xl-6">
				<div class="">
					<div class="">
						<event-information-card
							:title="anEvent.title"
							:location="singleAttendee.destinationLocation"
							:pickupLocation="singleAttendee.pickupLocation"
							:returnLocation="singleAttendee.returnLocation"
							:tripDate="singleAttendee.tripDate"
							:returnDate="singleAttendee.returnDate"
							:program-days="true"
							:type="anEvent.type"
							:loading="loading"
							></event-information-card>
					</div>
					<div class="">
						<event-customer-information
							:firstName="singleAttendee.firstName"
							:lastName="singleAttendee.lastName"
							:email="singleAttendee.email"
							:phone="singleAttendee.phone ? singleAttendee.phone : 'N/A'"
							:gender="singleAttendee.gender"
							:loading="loading"
							></event-customer-information>
					</div>
				</div>
			</div>
			<div class="col-xl-6">
				<div class="">
					<div class="">
						<event-information-description-card
							:title="anEvent.title"
							:description="anEvent.description"
							:status="anEvent.status"
							:banner="anEvent.banner"
							:loading="loading"
							:attendee="true"
							></event-information-description-card>
					</div>
				</div>
			</div>
		</div>

		<!-- <div>
			<b-breadcrumb>
				<b-breadcrumb-item>Upcoming events</b-breadcrumb-item>
				<p class="px-2">></p>
				<router-link to="/events/1/attendee-list">
					<b-breadcrumb-item>Attendees list</b-breadcrumb-item>
				</router-link>
			</b-breadcrumb>
		</div>  -->
	</div>
</template>

<script>
import eventServiceInstance from '@/api/event_service'
import EventInformationCard from '@/components/modules/events/EventInformationCard.vue'
import EventInformationDescriptionCard from '@/components/modules/events/EventInformationDescriptionCard.vue'
import EventCustomerInformation from '@/components/modules/events/EventCustomerInformation.vue'
import { reactive, Vue } from 'vue'

export default {
  components: {
	EventInformationCard,
    EventInformationDescriptionCard,
    EventCustomerInformation,
  },
  props: {
    eventRequestHandler: {
      type: Vue,
      required: false,
    },
  },
  data () {
    return {
      menuTabs: [
        {
          routeName: 'EventList',
          label: 'Event Requests'
        },
        {
          routeName: 'UpcomingEvents',
          label: 'Upcoming Events'
        }
      ],
	  anEvent: reactive({
        id: '',
        title: '',
        description: '',
        location: '',
        payment: '',
        returnTrip: false,
        tripDate: null,
        returnDate: null,
        attendeesEstimate: 0,
        type: '',
        banner: '',
        status: '',
        eventLink: null,
      }),
      loading: true,
      singleAttendee: reactive({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        gender: '',
        pickupLocation:'',
        destinationLocation: '',
        returnLocation: '',
        tripDate: null,
        returnTrip: false,
        returnDate: null,
        eventId: '',
        attendingDays: null,
        status: '',
        createdAt: null,
        updatedAt: null
	  }),
	  attendeesNotificationLoading: false
    }
  },
  created () {
	this.eventRequestHandler.$on('notifyAttendees', () => {
      this.notifyAttendees()
    })

    this.initEvent()
  },
  emits: ['update:attendeesNotificationLoading'],
  methods: {
	async initEvent() {
      this.loading = true
    //   this.errorLoading = false
      try {
        const res = await eventServiceInstance.getSingleEvent(this.$route.params.eventId)

        if ([200, 201].includes(res.status)) {
          this.anEvent = res.data.data
          this.getSingleAttendee()
		  this.$emit('update:eventStatus', this.anEvent.status)
        }
      } catch(error) {
        const msg =
          error.response && error.response.data
            ? error.response.data.message
            : 'An error occured, please try again.'
        this.$swal().showValidationMessage(msg)
      }
    },

    async getSingleAttendee () {
		try {
			const res = await eventServiceInstance.getAnAttendee(this.$route.params.attendeeId)

			if ([200, 201].includes(res.status)) {
				this.singleAttendee = res.data
			}
		} catch(error) {
			const msg =
			  error.response && error.response.data
				  ? error.response.data.message
				  : 'An error occured, please try again.'
			  this.$swal().showValidationMessage(msg)
		} finally {
			this.loading = false
		}
    },

	async notifyAttendees() {
      this.$emit('update:attendeesNotificationLoading', true)

      try {
        const res = await eventServiceInstance.notifySingleAttendee(this.$route.params.attendeeId)
        if ([200, 201].includes(res.status)) {
          this.$bvToast.show('attendee-route-notification')
          this.$bvModal.hide('notify-attendees-modal')
          this.getSingleAttendee()
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      } finally {
        this.$emit('update:attendeesNotificationLoading', false)
      }
    },
  },
  beforeDestroy() {
	this.eventRequestHandler.$off('notifyAttendees')
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';

.route-code {
  background: #d3dce6 !important;
  color: black;
  font-size: 12px;
}
.potty {
  padding-left: 35px;
}
img {
  height: 150px;
  width: 450px;
  border-radius: 5px;
}
.logo-pending {
  color: rgb(219, 219, 46);
}
.logo-accepted {
  color: #0dac5c;
}
.logo-queried {
  color: #4848ed;
}
.logo-rejected {
  color: #e13d45;
}
.bottom {
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: center;
}

.bell-img {
  width: 20px;
  height: 20px;
}

.mybtn {
  background: #4848ed;
  outline: none;
  border: none;
}

.-mt-4{
  margin-top: -1.5rem;
}
 
.col-xl-6{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.inactive {
  color: #6e84a3;
}

.breadcrumb-item {
  & a {
    color: #6e84a3;

    &:hover {
      color: #09090f;
    }
  }

  &.active {
    font-weight: 500;
    color: #09090f;
  }
}
</style>
